import AppBlockAsTable from "@/components/AppBlockAsTable";
import InfoForm from "@/components/methodist/courses/InfoForm";

import { mapActions } from "vuex";

export default {
  components: {
    AppBlockAsTable,
    InfoForm,
  },
  data: () => ({
    form: {
      name: "",
      categoryId: "",
      description: "",
    },
    lessons: [],
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
  },
}