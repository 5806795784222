<template>
  <el-form class="up-info-form" :model="form" :rules="rules" ref="form">
    <el-form-item label="Название" prop="name">
      <el-input v-model="form.name" placeholder="Название" />
    </el-form-item>
    <el-form-item label="Категория" prop="categoryId">
      <el-select
        v-model="form.categoryId"
        placeholder="Выберите категорию курса"
        popper-class="up-choose-category-popover"
        @visible-change="openModalChooseCategory"
      >
        <template v-for="direction in coursesTree">
          <template v-if="direction.childs">
            <el-option
              v-for="category in direction.childs"
              :key="category.id"
              :label="category.name"
              :value="category.id"
            />
          </template>
        </template>
      </el-select>
    </el-form-item>
    <el-form-item>
      <vue-editor v-model="form.description" placeholder="Описание" />
    </el-form-item>
    <ModalChooseCategory
      :visible.sync="modalvisible"
      :courses-tree="coursesTree"
      @choose-category="chooseCategoryHandler"
    />
  </el-form>
</template>

<script>
import ModalChooseCategory from "@/components/methodist/courses/ModalChooseCategory";
import { VueEditor } from "vue2-editor";

import validatableFormMixin from "@/mixins/validatableForm.mixin";

const requiredValidation = [
  { required: true, message: "Необходимо заполнить", trigger: "blur" },
];

export default {
  name: "CourseInfoForm",
  mixins: [validatableFormMixin],
  components: { ModalChooseCategory, VueEditor },
  props: {
    value: null,
  },
  data: () => ({
    coursesTree: [],
    rules: {
      name: requiredValidation,
      categoryId: requiredValidation,
    },
    modalvisible: false,
  }),
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async fetchCoursesTree() {
      try {
        const res = await this.$axios.get("/constructor/tree");

        this.coursesTree = res.data;
      } catch (e) {
        //
      }
    },
    openModalChooseCategory(visible) {
      if (!visible) return;
      this.modalvisible = true;
    },
    chooseCategoryHandler(categoryId) {
      this.form.categoryId = categoryId;
      this.modalvisible = false;
    },
  },
  created() {
    this.fetchCoursesTree();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/methodist/courses/info-form.scss";
</style>

<style lang="scss">
.up-choose-category-popover {
  display: none !important;
}
</style>