<template>
  <div>
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <div v-loading="!isDataReceived" class="up-course">
      <h1 class="up-main-title">Редактирование (просмотр) курса</h1>
      <InfoForm v-model="form" ref="info-form" />
      <AppBlockAsTable v-if="lessons.length > 0" class="up-course__lessons">
        <template slot="header">
          <p
            class="up-course__lessons-type"
            :class="{ ml: lessons.length > 1 }"
          >
            Этап
          </p>
          <p class="up-course__lessons-name">Название</p>
        </template>
        <div v-for="(l, i) in lessons" :key="i" class="up-table__item">
          <div v-if="lessons.length > 1" class="up-course__move-buttons">
            <i
              v-if="i > 0"
              class="el-icon-top"
              role="button"
              aria-label="Переместить упражнение"
              @click="changeLessonsOrder(i, i - 1)"
            />
            <i
              v-if="i != lessons.length - 1"
              class="el-icon-bottom"
              role="button"
              aria-label="Переместить упражнение"
              @click="changeLessonsOrder(i, i + 1)"
            />
          </div>
          <p class="up-course__lessons-type">
            <span class="is-pk-hidden">Этап:</span>{{ i + 1 }}.
            {{ l.type === "lecture" ? "Лекция" : "Тест" }}
          </p>
          <p class="up-course__lessons-name">
            <span class="is-pk-hidden">Название:</span>{{ l.name }}
          </p>
          <p class="up-course__lessons-edit">
            <i
              class="el-icon-edit-outline"
              role="button"
              @click="redirectToEditPage(l)"
            />
          </p>
        </div>
      </AppBlockAsTable>
      <footer class="up-course__footer">
        <el-button v-loading="loading" type="primary" @click="addLessonHandler">
          Добавить лекцию
        </el-button>
        <el-button
          v-loading="loading"
          type="primary"
          @click="addLessonHandler(null, 'test')"
        >
          Добавить тест
        </el-button>
      </footer>
      <el-button
        v-loading="loading"
        class="up-course__btn-save"
        type="success"
        @click="editCourse"
      >
        Сохранить
      </el-button>
    </div>
  </div>
</template>

<script>
import editNewCourseMixin from "@/mixins/methodist/edit-new-course.mixin";

import AppBreadcrumbs from "@/components/AppBreadcrumbs";

export default {
  name: "ShowCourse",
  mixins: [editNewCourseMixin],
  components: { AppBreadcrumbs },
  data: () => ({
    isDataReceived: false,
    loading: false,
    breadcrumbs: [
      {
        text: "Страница курсов",
        path: "/methodist/courses",
      },
      {
        text: "Просмотр (редактирование) курса",
      },
    ],
  }),
  methods: {
    async fetchCourseInfo() {
      try {
        const { data } = await this.$axios.get(
          `/constructor/course/${this.$route.params.id}`
        );

        this.form.name = data.name;
        this.form.categoryId = data.categoryId;
        this.form.description = data.description;
        this.lessons = data.lessons || [];
      } catch (e) {
        //
      } finally {
        this.isDataReceived = true;
      }
    },
    changeLessonsOrder(idx, idx2) {
      [this.lessons[idx], this.lessons[idx2]] = [
        this.lessons[idx2],
        this.lessons[idx],
      ];

      this.$forceUpdate();
    },
    async editCourse() {
      if (!(await this.$refs["info-form"].isValid()) || this.loading) return;

      this.loading = true;

      try {
        await this.$axios.post("/constructor/create", {
          id: this.$route.params.id,
          ...this.form,
          lessons: this.lessons,
        });

        this.$message({
          message: "Изменения сохранены!",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async redirectToEditPage(lesson) {
      await this.editCourse();

      localStorage.setItem("course-name", this.form.name);
      this.$router.push(`${this.$route.path}/${lesson.type}/${lesson.id}`);
    },
    async addLessonHandler(_, type = "lecture") {
      await this.editCourse();

      localStorage.setItem("course-name", this.form.name);
      this.$router.push(`${this.$route.path}/${type}/add`);
    },
  },
  created() {
    this.setNavigationText("Редактирование (просмотр) курса");

    this.fetchCourseInfo();

    localStorage.removeItem("lecture-preview-data");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/methodist/courses/new-show.scss";
</style>