<template>
  <el-dialog
    center
    modal-centered
    title="Выбор категории"
    custom-class="up-modal-choose"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-input v-model="searchValue" placeholder="Введите название" clearable />
    <template v-for="direction in filteredCoursesTree">
      <AppCollapse :key="direction.id" :ref="direction.id">
        <template slot="title">{{ direction.name }}</template>
        <el-button
          v-for="category in direction.childs"
          :key="`category-${category.id}`"
          class="up-modal-choose__button"
          type="text"
          @click="$emit('choose-category', category.id)"
        >
          {{ category.name }}
        </el-button>
      </AppCollapse>
    </template>
  </el-dialog>
</template>

<script>
import AppCollapse from "@/components/AppCollapse";

import filteredCousesMixin from "@/mixins/filteredCouses.mixin";

export default {
  name: "ModalChooseCategory",
  mixins: [filteredCousesMixin],
  components: { AppCollapse },
  props: {
    coursesTree: Array,
  },
  methods: {
    suitableCategories(categories) {
      return categories.reduce((acc, curr) => {
        const cName = curr.name.toLowerCase();
        const svalue = this.searchValue.toLowerCase();

        return cName.includes(svalue) ? [...acc, curr] : acc;
      }, []);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/modal-choose.scss";
</style>